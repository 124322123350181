import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useRouter } from "next/router";
import useSWR from "swr";
import { OrganizationFeatures, sdk } from "~lib";
import {
  getTotals,
  Method,
  OrderGateway,
} from "~features/checkout/helpers/getTotals";
import { usePointsService } from "~features/points/hooks/usePointsService";
import {
  FeeType,
  GetPosQuery,
  GetReferralCampaignByOrderIdQuery,
  IntegrationType,
  OrderChannel,
  OrderQuery,
  OrderStatus,
  OrderTransactionStatus,
  OrderType,
  UpdateOrderInput,
  VenueSeating,
} from "~graphql/sdk";
import { useReCaptchaSDK } from "~hooks/useReCaptcha";
import {
  ExtendedGraphQLError,
  getGraphQLError,
  handlePromise,
  showToast,
} from "~lib/helpers";
import { usePayment } from "./usePayment";
import { useSteps } from "./useSteps";
import { useNonSeatedMultibuy } from "~hooks/multibuy/non-seated";
import { useAccount } from "~hooks/useAccount";
import { useReferral } from "~hooks/useReferral";
import { useSeatedMultibuy } from "~features/seated-reservation/hooks/useSeatedMultibuy";
import { useOrganization } from "~hooks/useOrganization";
import { Cart } from "~features/checkout/cart";

const getOrder = async (_: string, orgId: string, orderId: string) =>
  sdk({ orgId })
    .order({ id: orderId })
    .then(({ order }) => {
      if (order?.id) {
        return order;
      }

      throw Error("Order not found");
    });

const pollOrder = async (_: string, orgId: string, orderId: string) =>
  sdk({ orgId })
    .pollOrder({ id: orderId })
    .then(({ pollOrder }) => {
      if (pollOrder?.id) {
        return pollOrder;
      }

      throw Error("Order not found");
    });

const getReferalCampaign = async (_: string, orgId: string, id: string) => {
  return sdk({ orgId }).getReferralCampaignByOrderId({
    id,
  });
};

const getPOS = async (_: string, orgId: string, posId: string) =>
  sdk({ orgId })
    .getPOS({ id: posId })
    .then((res) => res.pos);

const swrKey = "order";

interface useCheckoutProps {
  isRetrying?: boolean;
  onUpdateOrderError?: (error: ExtendedGraphQLError) => void;
}

export type SmsOrderConfirmation = {
  send: boolean;
  set: Dispatch<SetStateAction<boolean>>;
  fee: number;
};

export const useCheckout = ({
  isRetrying = false,
  onUpdateOrderError,
}: useCheckoutProps) => {
  const router = useRouter();
  const { isAdmin, user, isEventManager, isSalesOutlet } = useAccount();
  const { organization, hasFeature } = useOrganization();
  const [shouldPoll, setShouldPoll] = useState<boolean>(false);
  const [transactionError, setTransactionError] = useState(null);
  const [method, setMethod] = useState<Method>();
  const [partialPaymentMethod, setPartialPaymentMethod] = useState<Method>(
    null
  );
  const [sendSms, setSendSms] = useState(false);
  const { calculateReferralDiscount } = useReferral();
  const { recaptchaSdkGenerator } = useReCaptchaSDK();

  const { data: order, error, mutate } = useSWR<OrderQuery["order"], Error>(
    organization?.id && router?.query?.orderId
      ? [swrKey, organization?.id, router?.query?.orderId]
      : null,
    async (_: string, orgId: string, orderId: string) => {
      const result = await (shouldPoll
        ? pollOrder(_, orgId, orderId)
        : getOrder(_, orgId, orderId));

      // Pre-select sms on orders that are online (exludes pos and backoffice)
      // and that are for an event for membership (exludes points etc)
      if (
        result.channel === OrderChannel.Online &&
        (result.event || result.membership) &&
        !hasFeature(OrganizationFeatures.OrderSmsConfirmationDisabled)
      ) {
        setSendSms(true);
      }

      if (isRetrying) {
        setShouldPoll(false);
        return result;
      }

      if (
        [OrderTransactionStatus.Error, OrderTransactionStatus.Failure].includes(
          result.transactionStatus
        )
      ) {
        setTransactionError(true);
        setShouldPoll(false);
      }

      if (
        (result.status === OrderStatus.Pending ||
          result.status === OrderStatus.Hold) &&
        result.transactionStatus === OrderTransactionStatus.Pending
      ) {
        if (!shouldPoll) {
          setShouldPoll(true);
        }
      } else if (shouldPoll) {
        setShouldPoll(false);
      }

      return result;
    },
    {
      shouldRetryOnError: true,
      // when the payment is initiated refresh every 5 seconds
      ...(shouldPoll && { refreshInterval: 5000 }),
    }
  );

  const isBackOfficeOrder = isAdmin || isEventManager || isSalesOutlet;

  const { point } = usePointsService({
    enabled: order?.orderType === OrderType.PointPurchase,
  });

  const isPointsOrder = order?.orderType === OrderType.PointPurchase && point;

  const { data: posData, error: posError } = useSWR<GetPosQuery["pos"], Error>(
    organization?.id && order?.pos?.id
      ? ["pos", organization?.id, order?.pos?.id]
      : null,
    getPOS
  );

  const lineItems = useMemo(
    () => order?.lineItems?.edges?.map(({ node }) => node),
    [order?.id]
  );

  const { activePromotions: seatedPromotions } = useSeatedMultibuy(
    order?.event?.multiBuyPromotions || order?.membership?.multiBuyPromotions,
    lineItems
  );

  let cart: Cart;

  if (order?.event) {
    cart = new Cart(order.event.fees, order.event.multiBuyPromotions);
  } else if (order?.membership) {
    cart = new Cart(order.membership.fees, order.membership.multiBuyPromotions);
  }

  if (cart) {
    lineItems.forEach((li) =>
      cart.add(
        li.ticketType?.id ??
          li.membershipType?.id ??
          li.eventAddon?.id ??
          li.membershipAddon?.id ??
          li.pointItem?.id,
        li.price,
        li.quantity
      )
    );
    cart.addFee(order.transferFee ?? 0);
    cart.includeFees(true);
    cart.sendSmsConfirmation(sendSms);
    cart.isPos(order?.channel === OrderChannel.Pos);
  }

  const { activePromotions: promotions } = useNonSeatedMultibuy(
    order?.event?.multiBuyPromotions || order?.membership?.multiBuyPromotions,
    lineItems
  );

  const { step, navigateSteps } = useSteps({
    deliveryMethod: order?.deliveryMethod,
    buyerInformation: order?.buyerInformation,
    status: order?.status,
    transactionStatus: order?.transactionStatus,
    isAdmin,
  });

  const { data: referralCampaign } = useSWR<GetReferralCampaignByOrderIdQuery>(
    router?.query?.orderId
      ? ["referralCampaign", organization?.id, router?.query?.orderId]
      : null,
    getReferalCampaign
  );

  const ticketTotal = order?.lineItems?.edges?.reduce(
    (sum, lineItem) => sum + lineItem?.node?.price * lineItem?.node?.quantity,
    0
  );

  const referralReduction = calculateReferralDiscount(
    referralCampaign?.getReferralCampaignByOrderId,
    ticketTotal
  );

  if (cart && referralReduction && order?.referralId) {
    cart.setReferralCampaign(referralReduction);
  }

  const { createPayment, applyCoupon, removeCoupon } = usePayment({
    orderId: order?.id,
  });

  const gateways = (() => {
    let gateways: OrderGateway[] = [];

    if (order?.membership) {
      gateways = [...gateways, ...(order.membership?.gateways || [])];
    }

    if (order?.event) {
      gateways = [...gateways, ...(order.event?.gateways || [])];
    }

    if (isPointsOrder) {
      gateways = [...gateways, ...point.gateways];
    }

    const result: OrderGateway[] = [];
    for (const gateway of gateways) {
      // TEMP hack: Hide stripe but show stripe after pay
      // The Coro Classic 2024
      const isCoroClassic =
        gateway.type === IntegrationType.PaymentStripe &&
        order?.event?.id === "86e0f7ef-a318-4160-98cd-e9aacfd9062f";

      // Latin Power New Years Eve Party 2024
      const isLatinPower =
        gateway.type === IntegrationType.PaymentStripe &&
        order?.event?.id === "489d82b3-82a7-47a0-b458-d61499ff44c1";

      // Baseline 2024
      const isBaseline =
        gateway.type === IntegrationType.PaymentStripe &&
        order?.event?.id === "5ecf87b8-b418-4a53-884d-9c5e7c727e67";

      // Rhythm & Alps 2024
      const isRhythmAndAlpsSingles =
        gateway.type === IntegrationType.PaymentStripe &&
        order?.event?.id === "f7a382f3-93e4-4676-9db8-cd32d6b0bbbc";

      // Rhythm & Alps 2024
      const isRhythmAndAlpsBundled =
        gateway.type === IntegrationType.PaymentStripe &&
        order?.event?.id === "23e279d4-bb56-4c22-bde8-1ae2ca22d367";

      // The Longline Classic 2024
      const isLonglineClassic =
        gateway.type === IntegrationType.PaymentStripe &&
        order?.event?.id === "4256b7f4-0f8e-4de6-84af-a99d496fe910";

      // Rolling Meadows 2024
      const isRollingMeadows =
        gateway.type === IntegrationType.PaymentStripe &&
        order?.event?.id === "a2bcfea1-91c9-4c82-ba0c-63d9a6fb83b6";

      // Selwyn Sounds 2025
      const isSelwynSounds =
        gateway.type === IntegrationType.PaymentStripe &&
        order?.event?.id === "b2f5800f-f57f-4f3f-9b8e-0af85c0620f0";

      // The Christmas Portal
      const isChristmasPortal =
        gateway.type === IntegrationType.PaymentStripe &&
        organization?.id === "9c73fd82-205d-4005-9748-8f8335b6ced3";

      // Equitana
      const isEquitana =
        gateway.type === IntegrationType.PaymentStripe &&
        organization?.id === "8ee5ec4d-784f-4b59-ab18-94d24d9ae7a3";

      const hasFeatureFlag =
        gateway.type === IntegrationType.PaymentStripe &&
        hasFeature(OrganizationFeatures.StripeOnlyAfterpay);

      if (
        !(
          isCoroClassic ||
          isLatinPower ||
          isBaseline ||
          isRhythmAndAlpsSingles ||
          isRhythmAndAlpsBundled ||
          isLonglineClassic ||
          isRollingMeadows ||
          isSelwynSounds ||
          isChristmasPortal ||
          isEquitana ||
          hasFeatureFlag
        )
      ) {
        result.push({ ...gateway, bnplEnabled: false });
      }

      const hideAfterPayStripe =
        // Subsonic 2024 - Volunteer membership
        order?.membership?.id === "5d36e0e5-1b15-4e91-b6bf-45053d76e34c";

      if (gateway.bnplEnabled && !hideAfterPayStripe) {
        result.push({
          ...gateway,
          id: `${gateway.id}-afterpay`,
          name: "Afterpay",
          ...(gateway.bnplTransactionFeePercent != null && {
            transactionFeeType2: FeeType.Percentage,
            transactionFee2: gateway.bnplTransactionFeePercent,
          }),
        });
      }
    }

    return result;
  })();

  let selectedMethod = method;

  if (!selectedMethod && !order?.pos && !isAdmin) {
    selectedMethod = order?.defaultGateway?.id;
  }

  // Defaults to hold if admin
  if (!selectedMethod && isAdmin && step <= 2) {
    selectedMethod = order?.defaultGateway?.id;
  }

  const { totalWithTransactionFee, ...totals } = getTotals({
    step,
    order,
    method: selectedMethod,
    gateway: gateways.find((g) => selectedMethod?.startsWith(g.id)),
    gateways,
    userPoints: isBackOfficeOrder ? order?.user?.points : user?.points,
    userCredits: isBackOfficeOrder ? order?.user?.credits : user?.credits,
    referralReduction,
    partialPaymentMethod,
    cart: !hasFeature(OrganizationFeatures.OrderSmsConfirmationDisabled)
      ? cart
      : undefined,
  });

  const updateOrder = async (update: UpdateOrderInput) => {
    const sdkFn = await recaptchaSdkGenerator("updateOrder");
    const { data, error } = await handlePromise(async () =>
      sdkFn({ orgId: organization?.id }).updateOrder({
        id: order?.id,
        input: { ...update, step },
      })
    );

    if (error) {
      const e = getGraphQLError(error);
      if (onUpdateOrderError) {
        return onUpdateOrderError(e);
      }

      return showToast(e.message, "error");
    }

    void mutate(undefined, true);

    return {
      data,
      error,
    };
  };

  return {
    point,
    points: isPointsOrder && lineItems[0],
    isPointsOrder,
    gateways,
    activePromotions:
      (order?.event
        ? order?.event?.venue?.seating
        : order?.membership
        ? order?.membership?.venue?.seating
        : undefined) === VenueSeating.NonSeated
        ? promotions
        : seatedPromotions,
    applyCoupon,
    createPayment,
    error,
    isLoading: !order && !error,
    isLoadingPOS: !posData && !posError,
    isProcessing: !!shouldPoll,
    isNonSeated: isPointsOrder
      ? true
      : (order?.event
          ? order?.event?.venue?.seating
          : order?.membership
          ? order?.membership?.venue?.seating
          : undefined) === VenueSeating.NonSeated,
    method: selectedMethod,
    mutate,
    navigateSteps,
    order,
    posData,
    removeCoupon,
    setMethod,
    partialPaymentMethod,
    setPartialPaymentMethod,
    setProcessing: setShouldPoll,
    step,
    totals: {
      ...totals,
      totalWithTransactionFee,
    },
    updateOrder,
    transactionError,
    setTransactionError,
    referralCampaign,
    smsConfirmation: {
      send: !cart?.smsConfirmationFee ? false : sendSms,
      set: setSendSms,
      fee: !hasFeature(OrganizationFeatures.OrderSmsConfirmationDisabled)
        ? cart?.smsConfirmationFee ?? 0
        : 0,
    },
  };
};
