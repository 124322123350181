export enum OrganizationFeatures {
  ShowHiddenFees = "showHiddenFees",
  TicketTransfers = "ticketTransfers",
  DigitalEvents = "digitalEvents",
  VaccinePassport = "vaccinePassport",
  Tags = "tags",
  Points = "points",
  Fiji = "fiji",
  PinPaymentGateway = "pinPaymentGateway",
  DisablePageLoadTracing = "disablePageLoadTracing",
  TicketResale = "ticketResale",
  ResaleTicketPriceCollection = "resaleTicketPriceCollection",
  ResaleTicketQuantityColection = "resaleTicketQuantityCollection",
  PointOfSale = "pointOfSale",
  SMSMessageMedia = "smsMessageMedia",
  AuthDuringCheckout = "authDuringCheckout",
  LegacyGATicketSelection = "LegacyGATicketSelection",
  pointsOnHomepage = "pointsOnHomepage",
  social = "social",
  AddressFinder = "addressFinder",
  TravelDeals = "travelDeals",
  HideEventTime = "hideEventTime",
  StripeOnlyAfterpay = "stripeOnlyAfterpay",
  EventCalendarHomepage = "eventCalendarHomepage",
  LegacyConfirmationPage = "legacyConfirmationPage",
  LegacyShopify = "legacyShopify",
  RemoveBackgroundImageFromTicketPage = "removeBackgroundImageFromTicketPage",
  TicketDates = "ticketDates",
  OrderSmsConfirmationDisabled = "orderSmsConfirmationDisabled",
}
